<template>
  <div class="font">
    <ayl-berad-nav :nav="nav"/>
     <div class="content-box">
      <div class="content-main">
        <el-form :model="form" ref="form" :inline="true" label-position="left" style="display: inline;" class="search-box">
          <el-form-item label="报警类型" class="form">
            <div class="block" >
              <el-cascader
                :options= 'options'
                :show-all-levels="false"
                :props="props"
                collapse-tags
                ref="tree"
                v-model="arrN"
                @change="handleChange"
                clearable></el-cascader>
            </div>
          </el-form-item>
          <el-form-item label="报警等级" class="form">
            <el-select
            v-model="form.alarmType"
            allow-create
            clearable
            default-first-option
            @change="clickAlarmDispose"
            placeholder="请选择报警等级">
            <el-option
              v-for="(item, idx) in this.$enums.alarmType.allList()"
              :key="idx"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          </el-form-item>
          <el-form-item label="报警开始时间">
            <el-date-picker
              v-model="form.time"
              unlink-panels
              type="daterange"
              format="yyyy-MM-dd"
              :default-time="['00:00:00', '23:59:59']"
              value-format="timestamp"
              @change="clickAlarmDispose"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="处理状态" class="form">
            <el-select
            v-model="form.dealStatus"
            allow-create
            default-first-option
            clearable
            @change="clickAlarmDispose"
            placeholder="请选择">
            <el-option
              v-for="(item, idx) in this.$enums.alarmState.allList()"
              :key="idx"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          </el-form-item>
          <el-form-item label="处理人" class="form">
            <el-select
            v-model="form.dealName"
            allow-create
            default-first-option
            @change="clickAlarmDispose"
            clearable
            placeholder="请选择">
            <el-option
              v-for="(item, idx) in dealingData"
              :key="idx"
              :label="item.dealUserName"
              :value="item.dealName">
            </el-option>
          </el-select>
          </el-form-item>
          <el-form-item class="form">
            <el-input v-model="form.queryContent" placeholder="车牌号/司机" style="margin-right: 20px;"></el-input>
          </el-form-item>
        </el-form>
        <div slot="ctrl-button" class="mb24px mb24px" style="display: inline;vertical-align: middle;">
          <el-button type="primary" @click="clickAlarmDispose()" :disabled="tableLoading">查 询</el-button>
          <el-button type="primary" class="ml40px" @click="dialogExport=true" plain>导出</el-button>
        </div>
        <el-table
          :data="alarmDisposeData"
          :header-cell-style="{background:'rgba(250,250,250,1)'}"
          :key="currentPage"
          v-loading="tableDataLoading"
          style="width: 100%;">
          <el-table-column
            type="index"
            label="序号"
            width="50">
          </el-table-column>
          <el-table-column
            prop="plateNumber"
            label="车牌号"
            width="100">
          </el-table-column>
          <el-table-column
            label="驾驶员姓名"
            width="100">
            <template slot-scope="scope">
              <span>{{ scope.row.driverName || '- -'}}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="报警类型"
            width="120"
            :show-overflow-tooltip=true>
            <template slot-scope="scope">
              <span>{{ scope.row.alarmIdentifier || '- -'}}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="报警等级"
            width="100">
            <template slot-scope="scope">
              <span>{{ scope.row.alarmType || '- -'}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="alarmStartTime"
            label="报警开始时间"
            :formatter="formatDate"
            width="180">
          </el-table-column>
          <el-table-column
            label="报警位置"
            :show-overflow-tooltip=true>
            <template slot-scope="scope">
              <span>{{ scope.row.location || '- -'}}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="处理状态"
            width="100">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="detailsDeal(scope.row)" v-if="scope.row.dealStatus === '人工处理'" style="font-size: 14px;font-weight: 400;">{{scope.row.dealStatus}}</el-button>
              <span v-else>{{ scope.row.dealStatusDes || '- -'}}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="处理人"
            width="100">
            <template slot-scope="scope">
              <span>{{ scope.row.dealUserName || '- -'}}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="附件"
            width="80">
            <template slot-scope="scope">
              <el-button @click="annex(scope.row)" type="text" size="small" v-if="scope.row.attache === 1" style="font-size: 14px;font-weight: 400;">预览</el-button>
              <el-button @click="add(scope.row)" type="text" size="small" v-if="scope.row.attache !== 1" style="color: rgba(102, 102, 102, 1); font-size: 14px;font-weight: 400">暂无</el-button>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="150">
            <template slot-scope="scope">
              <el-button @click="details(scope.row)" type="text" size="small" style="font-size: 14px;font-weight: 400;float: left">查看详情</el-button>
              <el-button v-if="scope.row.attache === 1" @click="evidenceDerived(scope.row)" type="text" size="small" style="font-size: 14px;font-weight: 400;">导出证据</el-button>
              <el-button v-if="scope.row.attache === 0" type="text" size="small" style="font-size: 14px;font-weight: 400;color:#99c0ff">导出证据</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-size="PageSize" layout="total, prev, pager, next, jumper"
            :total="totalCount"
            style="text-align: right; margin: 20px 0">
          </el-pagination>
        </div>
        <!--导出-->
        <el-dialog title="选择导出的数据" :visible.sync="dialogExport" width="540px" center>
          <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
          <el-checkbox-group v-model="checkIndex" @change="handleCheckedCitiesChange">
            <el-checkbox :label="item.index" v-for="(item, idx) in checkList" :key="idx">{{item.value}}</el-checkbox>
          </el-checkbox-group>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="exportExcel" :loading="btnLoading">确 定</el-button>
            <el-button @click="dialogExport=false">取 消</el-button>
          </span>
        </el-dialog>

        <!-- 查看附件 -->
        <el-dialog title="查看附件" :visible.sync="dialogFormVisible">
          <div class="attachment-main">
            <div class="left-img">
              <div style="width: 100%;height: 100%;" v-for="(item,index) in videoUrl" :key="index">
                <video :src="item" type="video/mp4" controls="controls" preload="auto" style="width: 100%" v-if="videoIdx === 1 && videoType === 'mp4'"></video>
                <ayl-ts :url='item' style="width: 100%;height: 100%;" v-if="videoIdx === 1 && videoType === 'ts'"></ayl-ts>
                <img :src="url==='' ? playerOptions.images[0] : url" class="images" v-image-preview v-else-if="videoIdx !== 1">
              </div> 
              <!-- <video :src="videoUrl" type="video/mp4" controls="controls" preload="auto" style="width: 100%" v-if="videoIdx === 1"></video>
              <img :src="url===''?playerOptions.images[0]:url" class="images" v-image-preview v-else> -->
            </div>
            <div class="right-img">
              <div style="margin-bottom: 5px;" v-for="(item,index) in videoUrl" :key="index">
                <video :src="item" type="video/mp4" controls="controls" preload="auto" class="images" @click="videoItem(1)" v-if="videoType === 'mp4'"></video>
                <div style="width:100%;height: 100%" @click.stop="videoItem(1)">
                  <ayl-ts :url='item' style="width: 100%;height: 100%;" v-if="videoType === 'ts'" class="images"></ayl-ts>
                </div> 
              </div>
              <!-- <video :src="videoUrl" type="video/mp4" controls="controls" preload="auto" class="images" @click="videoItem(1)"></video> -->
              <img v-for="(item, idx) in playerOptions.images" :key="idx" @click="chg(idx,0)" :src="item" class="images">
            </div>
          </div>
      </el-dialog>


      <!-- 处理详情 -->
      <el-dialog
        title="处理详情"
        :visible.sync="detailsDialogVisible"
        width="30%"
        top="30vh"
        center>
        <div class="detail-main">
          <span>处理人：</span>
          <span>{{basicTitleData.dealName}}</span>
        </div>
        <div class="detail-main">
          <span>处理时间：</span>
          <span>{{basicTitleData.dealTime}}</span>
        </div>
        <div class="detail-main">
          <span>处理方式：</span>
          <span>{{basicTitleData.dealType}}</span>
        </div>
        <div class="detail-main">
          <span>处理内容：</span>
          <span>{{basicTitleData.dealContent}}</span>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="detailsDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="detailsDialogVisible = false">确 定</el-button>
        </span>
      </el-dialog>
      </div>
     </div>
  </div>
</template>

<script>
import mixins from '../../../utils/mixins'
import { async } from 'q';
  export default {
    mixins: [mixins.$exportCheckAll],
    data () {
      // eslint-disable-next-line no-unused-vars
      const vm = this
      return {
        arrN: [],
        nav: [{name: "安全中心", path: ""}, {name: "报警管理", path: ""},{name: "报警中心", path: ""}],
        list: [],
        dialogExport: false,
        dialogFormVisible: false,
        formLabelWidth: '120px',
        checkList: [],
        checkIndex: [],
        btnLoading: false,
        tableLoading: false,
        tableDataLoading: false,
        code: 1,
        dealingData: [],    //处理人

        detailsDialogVisible: false,
        basicTitleData: {
          dealName: null,
          dealTime: null,
          dealType: null,
          dealContent: null,
        },

        props: { multiple: true },
        options: [{
            value: 1,
            label: '全部',
            children: [{
                value: 2,
                label: 'ADAS报警',
                children: [
                    { value: '前向碰撞报警', label: '前向碰撞报警' },
                    { value: '车道偏离报警', label: '车道偏离报警' },
                ]
            }, {
                value: 7,
                label: 'DSM报警',
                children: [
                    { value: '疲劳驾驶报警', label: '疲劳驾驶报警' },
                    { value: '接打电话报警', label: '接打电话报警' },
                    { value: '抽烟报警', label: '抽烟报警' },
                    { value: '分神驾驶报警', label: '分神驾驶报警' },
                    { value: '驾驶员异常报警', label: '驾驶员异常报警' },
                ]
            }, {
                value: '右侧盲区报警',
                label: '右侧盲区报警',
                // children: [
                //     // { value: '低风险', label: '低风险' },
                //     // { value: '高风险', label: '高风险' },
                // ]
            }, {
                value: 12,
                label: '普通报警',
                children: [
                    { value: '超速', label: '超速' },
                    { value: '超时停车', label: '超时停车' },
                    { value: '路线偏移', label: '路线偏移' },
                    { value: '急加速', label: '急加速' },
                    { value: '急减速', label: '急减速' },
                ]
            }, {
                value: 15,
                label: '电子规则报警',
                children: [
                    { value: '电子围栏报警', label: '电子围栏报警' },
                    { value: '区域限速报警', label: '区域限速报警' },
                    { value: '电子围栏报警', label: '电子围栏报警' }
                ]
            }]
        }],
        form:{
          alarmIdentifierList: [],
          time:[],
          dealStatus: null,
          alarmType: null,
          dealName: null,
          queryContent: null,
        },
        alarmDisposeData: [],
         // 默认显示第几页
        currentPage:1,
        // 总条数，根据接口获取数据长度(注意：这里不能为空)
        totalCount:1,
        // 默认每页显示的条数（可修改）
        PageSize:10,

        url: '',
        videoUrl: [],
        videoType: null,
        videoIdx: 1,
        playerOptions : {
            playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
            autoplay: false, //如果true,浏览器准备好时开始回放。
            muted: false, // 默认情况下将会消除任何音频。
            loop: false, // 导致视频一结束就重新开始。
            preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
            language: 'zh-CN',
            aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
            fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
            sources: [],
            images: [],
            poster: "", //你的封面地址
            notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
            controlBar: {
              timeDivider: true,
              durationDisplay: true,
              remainingTimeDisplay: false,
              fullscreenToggle: true  //全屏按钮
            }
        },
      }
    },
    methods:{
      // eslint-disable-next-line no-unused-vars
      handleChange(value) {
        let parentCompany = []
        parentCompany = this.$refs.tree.getCheckedNodes(true)
        let arr = []
        for(let i in parentCompany){
          arr.push(parentCompany[i].value)
        }
        this.form.alarmIdentifierList = arr
        this.clickAlarmDispose()
      },
      async clickAlarmDispose(){
        this.currentPage = 1
        this.tableDataLoading = true
        this.alarmDisposeData = []
        let alarmDisposeDatas = await this.$api.lsitAlarmDeal({
          alarmIdentifierList: this.form.alarmIdentifierList,
          alarmType: this.form.alarmType,
          queryContent: this.form.queryContent,
          startTime: this.form.time === null ? null : this.form.time.length[0] ? null : this.form.time[0],
          endTime: this.form.time === null ? null : this.form.time.length[0] ? null : this.form.time[1],
          dealStatus: this.form.dealStatus,
          dealName: this.form.dealName,
          currentPage: this.currentPage,
          pageSize: this.PageSize,
        })
        //console.log(alarmDisposeDatas)
        this.alarmDisposeData = alarmDisposeDatas.data
        this.totalCount= alarmDisposeDatas.totalNum
        this.tableDataLoading = false
      },
      async clickAlarmDisposePage(){
        // this.currentPage = 1
        this.tableDataLoading = true
        this.alarmDisposeData = []
        let alarmDisposeDatas = await this.$api.lsitAlarmDeal({
          alarmIdentifierList: this.form.alarmIdentifierList,
          alarmType: this.form.alarmType,
          queryContent: this.form.queryContent,
          startTime: this.form.time === null ? null : this.form.time.length[0] ? null : this.form.time[0],
          endTime: this.form.time === null ? null : this.form.time.length[0] ? null : this.form.time[1],
          dealStatus: this.form.dealStatus,
          dealName: this.form.dealName,
          currentPage: this.currentPage,
          pageSize: this.PageSize,
        })
        //console.log(alarmDisposeDatas)
        this.alarmDisposeData = alarmDisposeDatas.data
        this.totalCount= alarmDisposeDatas.totalNum
        this.tableDataLoading = false
      },
      handleSizeChange(val) {
        // 改变每页显示的条数
        this.PageSize=val
        // 注意：在改变每页显示的条数时，要将页码显示到第一页
        this.currentPage=1
      },
         // 显示第几页
       handleCurrentChange(val) {
        // 改变默认的页数
        this.currentPage=val
        this.$nextTick(() =>{
          this.clickAlarmDisposePage()
        })
      },
      // 导出
      async exportExcel(){
        this.btnLoading = true
        try {
          const url = await this.$api.listAlarmDealExport({
            alarmIdentifierList: this.form.alarmIdentifierList,
            alarmType: this.form.alarmType,
            startTime: this.form.time[0],
            endTime: this.form.time[1],
            dealStatus: this.form.dealStatus,
            dealName: this.form.dealName,
            queryContent: this.form.queryContent,
            excelKey: 'listAlarmDealExport',
            indexList: this.checkIndex,
          })
          window.open(url)
          this.dialogExport = false
        // eslint-disable-next-line no-empty
        }catch (e) {}
        this.btnLoading = false
      },
      update(){
        this.$router.push({path:'/operation/updateInsurance'});
      },
      async annex(val){
        this.dialogFormVisible = true
        let videoData = await this.$api.findFileByPrefix({
          tenantId: val.tenantId,
          attacheKey: val.attacheKey
        })
        this.playerOptions.images = videoData[0].images
        if(videoData[0].sources[0].type === 'video/mp4') {
          this.videoType = 'mp4'
          this.playerOptions.sources = videoData[0].sources
          this.videoUrl = [videoData[0].sources[0].src]
        } else {
          var _this = this
          _this.videoType = 'ts'
          _this.videoUrl = [videoData[0].sources[0].src];

        }
        // this.playerOptions.sources = videoData[0].sources
        // this.videoUrl = this.playerOptions.sources[0].src
        //this.$router.push({path:'/safety-center/alarm-management/annexs',query: { attacheKey: val.attacheKey }});
      },
      details(val){    //报警未处理
        this.$router.push({path:'/safety-center/alarm-management/alarmDisposeDetails',query: { deviceId: val.deviceId, alarmStartTime: val.alarmStartTime, alarmType: val.alarmType, tenantId: val.tenantId, alarmName: val.alarmIdentifier}});
      },
      evidenceDerived(val){
        this.btnLoading = true      //证据导出
        this.$api.evidenceExport({
          attacheKey: val.attacheKey,
          deviceId: val.deviceId,
          alarmStartTime: val.alarmStartTime,
          alarmIdentifier: val.alarmIdentifier,
          alarmType: val.alarmType,
          tenantId: val.tenantId
        }).then((res) =>{
          window.open(res)
          this.dialogExport = false
        })
      },
      add(){
        this.$router.push({path:''});
      },
      async detailsDeal(val){
        this.detailsDialogVisible = true
        let detailsData= await this.$api.getAlarmEvidence({
          alarmName: val.alarmName,
          alarmType: val.alarmType,
          deviceId: val.deviceId,
          alarmStartTime: val.alarmStartTime,
          tenantId: val.tenantId
        })
        this.basicTitleData.dealName = detailsData.dealUserName
        this.basicTitleData.dealTime = this.$dayjs(detailsData.dealTime).format('YYYY-MM-DD HH:mm:ss')
        this.basicTitleData.dealType = detailsData.dealType
        this.basicTitleData.dealContent = detailsData.dealContent
      },
      // eslint-disable-next-line no-unused-vars
      formatDate(row, column) {
        let date = new Date(parseInt(row.alarmStartTime));
        let Y = date.getFullYear() + '-';
        let M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-';
        let D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' ';
        let h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':';
        let m = date.getMinutes()  < 10 ? '0' + date.getMinutes() + ':' : date.getMinutes() + ':';
        let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
        return Y + M + D + h + m + s;
      },
      chg:function(ind,val){
        this.url =this.playerOptions.images[ind]
        this.videoIdx = val
      },
      videoItem(val){
        this.videoIdx = val
      }
    },
    watch: {
      'videoIdx': {
        handler (newName) {
          this.videoIdx = newName
        },
        deep: true
      },

      alarmDisposeData: {
        deep: true,
        handler: function(v, vo) {
          let self = this
          self.alarmDisposeData = v
        }
      }
    },
    async mounted () {
      // 路由传值
      if (this.$route.query.alarmIdentifierList) {
        this.arrN = JSON.parse(this.$route.query.alarmIdentifierList)
        this.form.alarmIdentifierList = this.arrN.map(e => {
          return e[2]
        })
        this.form.time  = [new Date().setHours(0, 0, 0, 0), new Date().setHours(23, 59, 59, 99)]
      }
      this.clickAlarmDispose()
      await this.$search(this.table)
      // 导出
      this.$api.GetExcelExportKeyAndColumn({
        excelKey: "listAlarmDealExport"
      }).then((res)=>{
        this.checkList = res.chsList
        this.checkIndex = this.checkList.map(e=>{
          return e.index
        })
      })
      this.dealingData = await this.$api.getDealUserInfo({})
    },

  }
</script>
<style lang='sass' scoped>
.font
  font-family: MicrosoftYaHei,
/deep/ .table-view-edit
  padding-right: 10px
  color: #99c0ff

/deep/ .el-form--inline .el-form-item
  margin-right: 40px
/deep/ .el-table th > .cell
  color: rgba(41, 41, 41, 1)
  font-size: 14px

/deep/ .el-table .cell
  color: rgba(102, 102, 102, 1)
  font-size: 14px

.pagination
  width: 100%
  float: right
/deep/ .content-box .content-main
  padding: 24px 24px 74px 24px
.content-box /deep/ .el-form-item
  width: auto

.attachment-main
    width: 100%
    .right-img
      width: 20%
      height: 520px
      display: inline-block
      vertical-align: top
      margin-left: 2%
      overflow-y: auto
      .images
        width: 100%
    .left-img
      width: 78%
      height: 520px
      display: inline-block
      .images
        width: 100%
        height: 100%
    .class
      display: block
    .show
      display: none

.detail-main
  margin: 0px 30px
  font-size: 14px
  font-family: Microsoft YaHei
  font-weight: 400
  color: rgba(102,102,102,1)
  line-height: 46px
</style>
